import React, { useState, useEffect } from "react"
import Card from "./card"

const Articles = props => {
  const [sortType, setSortType] = useState("newest")
  const [limit, setLimit] = useState("50")
  // console.log(props.articles)

  useEffect(() => {
    setSortType(props.defaultSort)
    if (props.limit) {
      setLimit(props.limit)
    }
  }, [])

  return (
    <div>
      {props.showSort ? (
        <p>
          <span
            className={
              sortType === "newest" ? "has-text-weight-bold" : "has-opacity-65"
            }
            onClick={() => setSortType("newest")}
          >
            NEWEST RESOURCES
          </span>{" "}
          /{" "}
          <span
            className={
              sortType === "best" ? "has-text-weight-bold" : "has-opacity-65"
            }
            onClick={() => setSortType("best")}
          >
            BEST RESOURCES
          </span>
        </p>
      ) : (
        ""
      )}
      {props.articles && sortType === "best"
        ? props.articles
            .filter(article => article.node.approved === true)
            .sort((a, b) => b.node.users.length - a.node.users.length)
            .slice(0, limit)
            .map((article, i) => {
              return (
                <Card
                  article={article}
                  key={"article_" + article.node.strapiId}
                />
              )
            })
        : props.articles
            .filter(article => article.node.approved === true)
            .sort(
              (a, b) => new Date(b.node.createdAt) - new Date(a.node.createdAt)
            )
            .slice(0, limit)
            .map((article, i) => {
              return (
                <Card
                  article={article}
                  key={"article_" + article.node.strapiId}
                />
              )
            })}
    </div>
  )
}

export default Articles
