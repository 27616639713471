import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"

import ArticlesComponent from "../components/articles"
import Layout from "../components/layout"

export const query = graphql`
  query Category($id: String) {
    articles: allStrapiArticle(filter: { category: { id: { eq: $id } } }) {
      edges {
        node {
          strapiId
          title
          description
          createdAt
          content
          url
          users {
            id
          }
          category {
            name
          }
          media
        }
      }
    }
    category: strapiCategory(strapiId: { eq: $id }) {
      name
    }
  }
`

const Category = ({ data }) => {
  console.log(data)
  const articles = data.articles.edges
  const category = data.category.name

  return (
    <Layout>
      <Seo title={category} />
      <section className="hero is-dark is-small">
        <div className="hero-body">
          <div className="container pb-6 py-6">
            <div className="columns is-centered">
              <div className="column is-7 has-text-centered">
                <h1 className="is-size-3 has-text-weight-bold has-text-white title-font">
                  {category}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <ArticlesComponent
                articles={articles}
                defaultSort="newest"
                showSort={true}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Category
