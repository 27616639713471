import React, { useState, useEffect } from "react"
import axios from "axios"
// import { useStaticQuery, graphql } from "gatsby"

const Upvote = props => {
  const [upvotes, setUpvotes] = useState("0")
  const [article, setArticle] = useState()
  // const article = props.article

  const handleLogin = () => {
    document.querySelector(".signup-modal").classList.toggle("is-active")
  }

  const handleUpvote = e => {
    // console.log(article.users.find(x => x.id === props.loggedUser.id))
    article.users.push(props.loggedUser)
    e.preventDefault()
    axios
      .put(process.env.API_URL + "/articles/" + article.id, {
        users: article.users,
      })
      .then(function (res) {
        console.log(res)
        getArticle()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getArticle = () => {
    axios
      .get(process.env.API_URL + "/articles/" + props.articleId)
      .then(function (res) {
        // console.log(res.data)
        setArticle(res.data)
        setUpvotes(res.data.users.length)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    getArticle()
  }, [])

  return (
    <>
      {article && article.users.length > 0 ? (
        <a
          onClick={
            props.isAuth
              ? article.users.find(x => x.id === props.loggedUser.id)
                ? () => console.log("already voted")
                : e => handleUpvote(e)
              : () => handleLogin()
          }
          className={"has-text-dark	is-flex " + props.className}
          style={{ alignItems: "center" }}
        >
          {props.isAuth &&
          article.users.find(x => x.id === props.loggedUser.id) ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0.800003C4.92661 0.800003 0.800049 4.92656 0.800049 10C0.800049 15.0734 4.92661 19.2 10 19.2C15.0735 19.2 19.2 15.0734 19.2 10C19.2 4.92656 15.0735 0.800003 10 0.800003ZM13.4829 8.68281C13.4047 8.76094 13.3032 8.8 13.2 8.8C13.0969 8.8 12.9954 8.76094 12.9172 8.68281L10.4 6.16563V14.8C10.4 15.0219 10.2204 15.2 10 15.2C9.77974 15.2 9.60005 15.0219 9.60005 14.8V6.16563L7.08286 8.68281C6.92661 8.83906 6.67349 8.83906 6.51724 8.68281C6.36099 8.52656 6.36099 8.27344 6.51724 8.11719L9.71724 4.91719C9.75474 4.87969 9.79849 4.85156 9.84692 4.83125C9.94536 4.79063 10.0547 4.79063 10.1532 4.83125C10.2016 4.85156 10.2454 4.87969 10.2829 4.91719L13.4829 8.11719C13.6391 8.27344 13.6391 8.52656 13.4829 8.68281Z"
                fill="#181F40"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              className="is-20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0.800003C4.92349 0.800003 0.800049 4.92344 0.800049 10C0.800049 15.0766 4.92349 19.2 10 19.2C15.0766 19.2 19.2 15.0766 19.2 10C19.2 4.92344 15.0766 0.800003 10 0.800003ZM10 1.6C14.6438 1.6 18.4 5.35625 18.4 10C18.4 14.6438 14.6438 18.4 10 18.4C5.3563 18.4 1.60005 14.6438 1.60005 10C1.60005 5.35625 5.3563 1.6 10 1.6ZM9.96255 4.7875C9.94536 4.79063 9.92817 4.79531 9.91255 4.8C9.90005 4.80313 9.88755 4.80781 9.87505 4.8125C9.86255 4.81563 9.85005 4.82031 9.83755 4.825C9.81099 4.83906 9.78599 4.85625 9.76255 4.875C9.74536 4.88594 9.72817 4.89844 9.71255 4.9125C9.6938 4.93125 9.67661 4.95313 9.66255 4.975L6.51255 8.1125C6.35317 8.27188 6.35317 8.52813 6.51255 8.6875C6.67192 8.84688 6.92817 8.84688 7.08755 8.6875L9.60005 6.175V14.8C9.59849 14.9438 9.67349 15.0781 9.79849 15.1516C9.92349 15.2234 10.0766 15.2234 10.2016 15.1516C10.3266 15.0781 10.4016 14.9438 10.4 14.8V6.175L12.9125 8.6875C13.0719 8.84688 13.3282 8.84688 13.4875 8.6875C13.6469 8.52813 13.6469 8.27188 13.4875 8.1125L10.3375 4.975C10.3079 4.92969 10.2704 4.89219 10.225 4.8625C10.1797 4.83125 10.1282 4.81094 10.075 4.8C10.0391 4.79063 10 4.78594 9.96255 4.7875Z"
                fill="#181F40"
              />
            </svg>
          )}
          <span className="ml-1 is-size-7">{upvotes}</span>
        </a>
      ) : (
        <a
          onClick={props.isAuth ? e => handleUpvote(e) : () => handleLogin()}
          className={"has-text-dark	is-flex " + props.className}
          style={{ alignItems: "center" }}
        >
          <svg
            width="20"
            height="20"
            className="is-20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0.800003C4.92349 0.800003 0.800049 4.92344 0.800049 10C0.800049 15.0766 4.92349 19.2 10 19.2C15.0766 19.2 19.2 15.0766 19.2 10C19.2 4.92344 15.0766 0.800003 10 0.800003ZM10 1.6C14.6438 1.6 18.4 5.35625 18.4 10C18.4 14.6438 14.6438 18.4 10 18.4C5.3563 18.4 1.60005 14.6438 1.60005 10C1.60005 5.35625 5.3563 1.6 10 1.6ZM9.96255 4.7875C9.94536 4.79063 9.92817 4.79531 9.91255 4.8C9.90005 4.80313 9.88755 4.80781 9.87505 4.8125C9.86255 4.81563 9.85005 4.82031 9.83755 4.825C9.81099 4.83906 9.78599 4.85625 9.76255 4.875C9.74536 4.88594 9.72817 4.89844 9.71255 4.9125C9.6938 4.93125 9.67661 4.95313 9.66255 4.975L6.51255 8.1125C6.35317 8.27188 6.35317 8.52813 6.51255 8.6875C6.67192 8.84688 6.92817 8.84688 7.08755 8.6875L9.60005 6.175V14.8C9.59849 14.9438 9.67349 15.0781 9.79849 15.1516C9.92349 15.2234 10.0766 15.2234 10.2016 15.1516C10.3266 15.0781 10.4016 14.9438 10.4 14.8V6.175L12.9125 8.6875C13.0719 8.84688 13.3282 8.84688 13.4875 8.6875C13.6469 8.52813 13.6469 8.27188 13.4875 8.1125L10.3375 4.975C10.3079 4.92969 10.2704 4.89219 10.225 4.8625C10.1797 4.83125 10.1282 4.81094 10.075 4.8C10.0391 4.79063 10 4.78594 9.96255 4.7875Z"
              fill="#181F40"
            />
          </svg>
          <span className="ml-1 is-size-7">0</span>
        </a>
      )}
    </>
  )
}

export default Upvote
