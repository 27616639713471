import React from "react"
import { Link } from "gatsby"

const CategoryTag = props => {
  let icon
  let color

  switch (props.type) {
    case "Videos":
      color = "primary"
      icon = (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          className="is-20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4C1.448 4 1 4.448 1 5V45C1 45.552 1.448 46 2 46H48C48.552 46 49 45.552 49 45V5C49 4.448 48.552 4 48 4H2ZM3 6H6V9H3V6ZM8 6H12V9H8V6ZM14 6H18V9H14V6ZM20 6H24V9H20V6ZM26 6H30V9H26V6ZM32 6H36V9H32V6ZM38 6H42V9H38V6ZM44 6H47V9H44V6ZM21 19L31 25L21 31V19ZM3 41H6V44H3V41ZM8 41H12V44H8V41ZM14 41H18V44H14V41ZM20 41H24V44H20V41ZM26 41H30V44H26V41ZM32 41H36V44H32V41ZM38 41H42V44H38V41ZM44 41H47V44H44V41Z"
            fill="#45B6F2"
          />
        </svg>
      )
      break

    case "Courses":
      color = "danger"
      icon = (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          className="is-20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 2.9375L0 15.5312L2 16.6934V31.1875C0.842571 31.6048 0 32.7065 0 34C0 34.578 0.185092 35.0113 0.396484 35.4922C0.607876 35.9731 0.873202 36.4629 1.13477 36.9141C1.65789 37.8165 2.17773 38.5684 2.17773 38.5684L3 39.7617L3.82227 38.5684C3.82227 38.5684 4.34211 37.8165 4.86523 36.9141C5.1268 36.4629 5.39212 35.9731 5.60352 35.4922C5.81491 35.0113 6 34.578 6 34C6 32.7065 5.15743 31.6048 4 31.1875V17.8555L25 30.0625L50 15.5312L25 2.9375ZM11.1992 24.3008C8.75922 28.5208 7.96 33.35 8 40C16.64 40 23 42.5 25 46C27 42.5 33.36 40 42 40C42.04 33.35 41.2408 28.5208 38.8008 24.3008L26.0293 31.7207C25.7093 31.9107 25.36 32 25 32C24.64 32 24.2907 31.9107 23.9707 31.7207L11.1992 24.3008ZM3 33C3.55455 33 4 33.4455 4 34C4 33.972 3.93509 34.3153 3.77148 34.6875C3.60788 35.0597 3.3732 35.4989 3.13477 35.9102C3.06748 36.0262 3.06609 36.0155 3 36.125C2.93391 36.0155 2.93252 36.0262 2.86523 35.9102C2.6268 35.4989 2.39212 35.0597 2.22852 34.6875C2.06491 34.3153 2 33.972 2 34C2 33.4455 2.44545 33 3 33Z"
            fill="#e96463"
          />
        </svg>
      )
      break

    case "Articles":
      color = "success"
      icon = (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          className="is-20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7,2v46h36V2H7z M27,4h8v13l-4-3l-4,3V4z M30,40H15c-0.553,0-1-0.448-1-1s0.447-1,1-1h15c0.553,0,1,0.448,1,1 S30.553,40,30,40z M35,35H15c-0.553,0-1-0.448-1-1s0.447-1,1-1h20c0.553,0,1,0.448,1,1S35.553,35,35,35z M14,29c0-0.552,0.447-1,1-1 h15c0.553,0,1,0.448,1,1s-0.447,1-1,1H15C14.447,30,14,29.552,14,29z M35,25H15c-0.553,0-1-0.448-1-1s0.447-1,1-1h20 c0.553,0,1,0.448,1,1S35.553,25,35,25z"
            fill="#8FC238"
          />
        </svg>
      )
      break

    case "People":
      color = "warning"
      icon = (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          className="is-20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43,46H7c-0.275,0-0.538-0.113-0.727-0.313c-0.189-0.199-0.287-0.468-0.272-0.742c0.335-5.96,4.896-7.835,8.561-9.343 c2.194-0.902,4.858-2.177,5.431-3.559C19.996,32.028,19.998,32.015,20,32c-3.715-0.422-8-0.714-8-3c0-0.281,0.119-0.55,0.326-0.739 c0.781-0.712,1.175-3.663,1.266-6.517C13.817,14.678,14.158,4,25.1,4c2.184,0,3.665,0.988,4.293,1.508 c1.901,0.077,3.464,0.721,4.648,1.916c2.984,3.011,2.935,8.752,2.89,13.817c-0.025,2.905-0.055,6.198,0.741,6.918 C37.881,28.349,38,28.618,38,28.9c0,2.378-5.722,2.803-8,3.1c0.002,0.033,0.004,0.066,0.005,0.101 c0.602,1.347,3.27,2.612,5.433,3.501c3.665,1.508,8.227,3.383,8.561,9.343c0.016,0.274-0.082,0.543-0.271,0.742 C43.538,45.887,43.275,46,43,46z M30.01,32.307h0.01H30.01z M28.8,31.598c0,0,0,0.001,0,0.002C28.8,31.599,28.8,31.598,28.8,31.598z M28.8,31.596L28.8,31.596L28.8,31.596z"
            fill="#EBA83C"
          />
        </svg>
      )
      break

    case "Podcasts":
      color = "purple"
      icon = (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          className="is-20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M 25 0 C 18.921875 0 14 4.785156 14 10.71875 L 14 11 L 21.5 11 C 22.050781 11 22.5 11.449219 22.5 12 C 22.5 12.550781 22.050781 13 21.5 13 L 14 13 L 14 15 L 21.5 15 C 22.050781 15 22.5 15.449219 22.5 16 C 22.5 16.550781 22.050781 17 21.5 17 L 14 17 L 14 19 L 21.5 19 C 22.050781 19 22.5 19.449219 22.5 20 C 22.5 20.550781 22.050781 21 21.5 21 L 14 21 L 14 23.28125 C 14 29.214844 18.921875 34 25 34 C 31.078125 34 36 29.214844 36 23.28125 L 36 21 L 28.5 21 C 27.945313 21 27.5 20.550781 27.5 20 C 27.5 19.449219 27.945313 19 28.5 19 L 36 19 L 36 17 L 28.5 17 C 27.945313 17 27.5 16.550781 27.5 16 C 27.5 15.449219 27.945313 15 28.5 15 L 36 15 L 36 13 L 28.5 13 C 27.945313 13 27.5 12.550781 27.5 12 C 27.5 11.449219 27.945313 11 28.5 11 L 36 11 L 36 10.71875 C 36 4.785156 31.078125 0 25 0 Z M 9.8125 17.125 C 9.402344 17.210938 9.113281 17.582031 9.125 18 L 9.125 23 C 9.125 30.714844 14.6875 37.183594 22 38.59375 L 22 44 L 28 44 L 28 38.59375 C 35.3125 37.183594 40.875 30.714844 40.875 23 L 40.875 18 C 40.875 17.515625 40.484375 17.125 40 17.125 C 39.515625 17.125 39.125 17.515625 39.125 18 L 39.125 23 C 39.125 30.800781 32.800781 37.125 25 37.125 C 17.199219 37.125 10.875 30.800781 10.875 23 L 10.875 18 C 10.878906 17.75 10.773438 17.511719 10.589844 17.34375 C 10.402344 17.175781 10.15625 17.09375 9.90625 17.125 C 9.875 17.125 9.84375 17.125 9.8125 17.125 Z M 15.5 46 C 13.585938 46 12.03125 47.5625 12.03125 49.46875 L 12 50 L 37.875 49.9375 L 37.90625 49.46875 C 37.90625 47.5625 36.351563 46 34.4375 46 Z"
            fill="#4A5EC7"
          />
        </svg>
      )
      break

    case "Books":
      color = "teal"
      icon = (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          className="is-20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M 12 39 L 44 39 L 44 2 L 12 2 C 8.691406 2 6 4.691406 6 8 L 6 42.417969 C 6 45.496094 8.691406 48 12 48 L 44 48 L 44 46 L 12 46 C 9.832031 46 8 44.359375 8 42.417969 C 8 40.5 9.757813 39 12 39 Z M 14 10.5 C 14 10.222656 14.222656 10 14.5 10 L 36.5 10 C 36.777344 10 37 10.222656 37 10.5 L 37 15.5 C 37 15.777344 36.777344 16 36.5 16 L 14.5 16 C 14.222656 16 14 15.777344 14 15.5 Z"
            fill="#3BADA2"
          />
        </svg>
      )
      break
    default:
      color = "primary"
      icon = ""
  }
  return (
    <div className="control">
      <Link to={"/" + props.type} className="tags has-addons">
        <span className={"tag is-light is-" + color}>{icon}</span>
        <span className={"tag is-" + color}>{props.type}</span>
      </Link>
    </div>
  )
}

export default CategoryTag
